var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-col',{staticClass:"d-none d-lg-flex p-0",attrs:{"lg":"8","md":"6"}},[_c('SplashScreen')],1),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" "+_vm._s(_vm.$t('common.contact'))+" MyFlight Connect ")]),_c('validation-observer',{ref:"contactValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.name'),"label-for":"contact-name"}},[_c('validation-provider',{staticClass:"validation-required",attrs:{"name":_vm.$t('user.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"contact-name","state":errors.length > 0 ? false : null,"name":"contact-name"},model:{value:(_vm.contact.name),callback:function ($$v) {_vm.$set(_vm.contact, "name", $$v)},expression:"contact.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('login.email'),"label-for":"contact-email"}},[_c('validation-provider',{staticClass:"validation-required",attrs:{"name":_vm.$t('login.email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"contact-email","state":errors.length > 0 ? false : null,"name":"contact-email"},model:{value:(_vm.contact.email),callback:function ($$v) {_vm.$set(_vm.contact, "email", $$v)},expression:"contact.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('user.phone'),"label-for":"emergencyTel","rules":"required"}},[_c('validation-provider',{staticClass:"validation-required",attrs:{"name":_vm.$t('user.phone'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-tel-input',{staticClass:"form-control",class:_vm.phoneInput && !_vm.phoneInput.valid && _vm.contact.phoneNumber ? 'is-invalid' : null,attrs:{"default-country":_vm.userLang},on:{"validate":function (val) { return (_vm.phoneInput = val); }},model:{value:(_vm.contact.phoneNumber),callback:function ($$v) {_vm.$set(_vm.contact, "phoneNumber", $$v)},expression:"contact.phoneNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.phoneInput && !_vm.phoneInput.valid && _vm.contact.phoneNumber)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('validator.invalid_tel.message')))]):_vm._e()]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('user.company_name'),"label-for":"company-name"}},[_c('validation-provider',{staticClass:"validation-required",attrs:{"name":"company-name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-name","state":errors.length > 0 ? false : null,"name":"company-name"},model:{value:(_vm.contact.companyName),callback:function ($$v) {_vm.$set(_vm.contact, "companyName", $$v)},expression:"contact.companyName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('user.company_website'),"label-for":"company-website"}},[_c('b-form-input',{attrs:{"id":"company-website","name":"company-website"},model:{value:(_vm.contact.webSiteUrl),callback:function ($$v) {_vm.$set(_vm.contact, "webSiteUrl", $$v)},expression:"contact.webSiteUrl"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('common.message'),"label-for":"message"}},[_c('b-form-textarea',{attrs:{"rows":"5"},model:{value:(_vm.contact.message),callback:function ($$v) {_vm.$set(_vm.contact, "message", $$v)},expression:"contact.message"}})],1),_c('b-button',{staticClass:"mt-1",attrs:{"type":"submit","block":""},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('font-awesome-icon',{attrs:{"icon":"paper-plane"}}),_vm._v(" "+_vm._s(_vm.$t('action.send'))+" ")],1)],1)]}}])}),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('login.already_have_account')))]),_c('b-link',{attrs:{"to":{ name: 'login' }}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('login.sign_in_instead')))])])],1),_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('login.new_on_platform')))]),_c('b-link',{attrs:{"to":{ name: 'register' }}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('login.create_an_account')))])])],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }